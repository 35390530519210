import { useStaticQuery, graphql } from "gatsby";

function Getmuseuminfo(museumname) {

  const museumimageinfo = useStaticQuery(graphql`
        query museumimageinfo {
          britishmuseum: file(relativePath: {eq: "BritishMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
        louvre: file(relativePath: {eq: "LouvreMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          nationalgallery: file(relativePath: {eq: "NationalGallery.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          nhm: file(relativePath: {eq: "NaturalHistoryMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          orsay: file(relativePath: {eq: "MuseeOrsay.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          orangerie: file(relativePath: {eq: "orangeriewaterlilies.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          rodin: file(relativePath: {eq: "RodinMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          neues: file(relativePath: {eq: "NeuesMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          pergamon: file(relativePath: {eq: "PergamonMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          altegalerie: file(relativePath: {eq: "AlteGalerie.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
          capitoline: file(relativePath: {eq: "CapitolineMuseum.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }

}`)


  const museuminfo = [
    {
      name: 'BritishMuseum',
      ioSurl: 'https://apps.apple.com/gb/app/british-museum-guide/id551275212',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.bm.london.vusiem',
      amazonurl: 'https://www.amazon.co.uk/Museum-Tour-Guides-Ltd-intro/dp/B009NGY13E',
      url:  'https://www.vusiem.com/App/British-Museum-Tour-Guide',
      title: 'British Museum',
      width: '40%',
      fluidimage: museumimageinfo.britishmuseum.childImageSharp.fluid
    },
    {
      name: 'NationalGallery',
      ioSurl: 'https://apps.apple.com/gb/app/national-gallery-guide/id1034118469',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.ng.london.vusiem',
      amazonurl: 'https://www.amazon.co.uk/Museum-Tour-Guides-Ltd-National/dp/B014K2T1XA',
      url: 'https://www.vusiem.com/App/National-Gallery-Tour-Guide',
      title: 'National Gallery',
      width: '20%',
      fluidimage: museumimageinfo.nationalgallery.childImageSharp.fluid
    },
    {
      name: 'NHM',
      ioSurl: 'https://apps.apple.com/gb/app/natural-history-museum-guide/id561749754',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.nhm.london.vusiem',
      amazonurl: 'https://www.amazon.com/Museum-Tour-Guides-Ltd-Natural/dp/B00BBYJOF6',
      url:null,
      title: 'Natural History Museum',
      width: '40%',
      fluidimage: museumimageinfo.nhm.childImageSharp.fluid
    },
    {
      name: 'Louvre',
      ioSurl: 'https://apps.apple.com/gb/app/louvre-visitor-guide/id1076660928',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.lvr.paris.vusiem',
      amazonurl: 'https://www.amazon.co.uk/Museum-Tour-Guides-Ltd-Louvre/dp/B01H9U0Y6S',
      url:'https://www.vusiem.com/App/Louvre-Museum-Tour-Guide',
      title: 'Louvre',
      width: '40%',
      fluidimage: museumimageinfo.louvre.childImageSharp.fluid
    },
    {
      name: 'Orsay',
      ioSurl: 'https://apps.apple.com/gb/app/musee-dorsay-guide/id1076663442',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.dor.paris.vusiem',
      amazonurl: 'https://www.amazon.co.uk/Museum-Tour-Guides-Ltd-intro/dp/B01F7LHZAY',
      url:'https://www.vusiem.com/App/Orsay-Museum-Tour-Guide',
      title: 'Musee d\'Orsay',
      width: '30%',
      fluidimage: museumimageinfo.orsay.childImageSharp.fluid
    },
    {
      name: 'Orangerie',
      ioSurl: 'https://apps.apple.com/gb/app/orangerie-museum-guide/id1093119466',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.orangerie.paris.vusiem',
      amazonurl: 'https://www.amazon.com/Museum-Tour-Guides-Ltd-Orangerie/dp/B01HEEFLTO',
      url:null,
      title: 'Orangerie',
      width: '30%',
      fluidimage: museumimageinfo.orangerie.childImageSharp.fluid
    },
    {
      name: 'Neues',
      ioSurl: 'https://apps.apple.com/gb/app/neues-museum-full-edition/id1063637832',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.neues.berlin.vusiem',
      amazonurl: 'https://www.amazon.com/Museum-Tour-Guides-Ltd-museum/dp/B01IYGIOK4',
      url:null,
      title: 'Neues museum',
      width: '35%',
      fluidimage: museumimageinfo.neues.childImageSharp.fluid
    },
    {
      name: 'Pergamon',
      ioSurl: 'https://apps.apple.com/gb/app/pergamon-museum-full-edition/id1066109570',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.perg.berlin.vusiem',
      amazonurl: 'https://www.amazon.com/Museum-Tour-Guides-Ltd-Pergamon/dp/B01IWZNZRY',
      url:null,
      title: 'Pergamon Museum',
      width: '30%',
      fluidimage: museumimageinfo.pergamon.childImageSharp.fluid
    },
    {
      name: 'AlteGalerie',
      ioSurl: 'https://apps.apple.com/gb/app/alte-national-gallery-full/id1064387111',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.ang.berlin.vusiem',
      amazonurl: 'https://www.amazon.com/Museum-Tour-Guides-Ltd-National/dp/B01IYFVMBS',
      url:null,
      title: 'Alte Galerie',
      width: '35%',
      fluidimage: museumimageinfo.altegalerie.childImageSharp.fluid
    },
    {
      name: 'Capitoline',
      ioSurl: 'https://apps.apple.com/gb/app/alte-national-gallery-full/id1064387111',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.ang.berlin.vusiem',
      amazonurl: 'https://play.google.com/store/apps/details?id=air.com.ang.berlin.vusiem',
      url:'https://www.vusiem.com/App/Capitoline-Museum-Tour-Guide',
      title: 'Capitoline museum',
      width: '50%',
      fluidimage: museumimageinfo.capitoline.childImageSharp.fluid
    },
    {
      name: 'Rodin',
      ioSurl: 'https://apps.apple.com/gb/app/alte-national-gallery-full/id1064387111',
      androidurl: 'https://play.google.com/store/apps/details?id=air.com.ang.berlin.vusiem',
      amazonurl: 'https://www.amazon.com/Museum-Tour-Guides-Ltd-Rodin/dp/B01LXPFICS',
      url:null,
      title: 'Rodin museum',
      width: '50%',
      fluidimage: museumimageinfo.rodin.childImageSharp.fluid

    },
  ];

  return museuminfo.filter(museum=>(museum.name===museumname))[0]
};

export default Getmuseuminfo
